
    import Vue from 'vue'
    import {mapGetters} from 'vuex'
    import NewsBlock from './NewsBlock.vue'
    import api from '@api/article/apiMethod'
    import authApi from '@api/auth/apiMethod'
    import dict from '@custom/dict'
    import { Form, message } from 'ant-design-vue';
    let myform:any = Form

    export default Vue.extend({
        name: 'NewsPanel',
        components: {
            NewsBlock
        },
        data () {
            return {
                newsList: [],
                rotateList: [],
                dict,
                activeTab: 1,
                form: myform.createForm(this, { name: 'coordinated' }),
                loginLoading: false
            }
        },
        computed: {
            ...mapGetters([
                'userInfo',
                'comConfig'
            ])
        },
        methods: {
            queryArticle () {
                const self = this as any
                api.articleQuery({
                    data: {
                        filterConds: self.buildFilterConds({
                            isTop: '1'
                        })
                    },
                    success (res: any) {
                        if (res.data.length > 0) {
                            self.newsList = res.data
                        }
                    }
                })
            },
            querySwiperArticle () {
                const self = this as any
                api.articleQuery({
                    data: {
                        filterConds: self.buildFilterConds({
                            isRotate: '1'
                        })
                    },
                    success (res: any) {
                        if (res.data.length > 0) {
                            self.rotateList = res.data
                        }
                    }
                })
            },
            buildFilterConds (obj: any) {
                const filterConds = []
                for (const key of Object.keys(obj)) {
                    if (obj[key]) {
                        filterConds.push({
                            fieldName: key,
                            fieldValue: obj[key]
                        })
                    }
                }
                return filterConds
            },
            carouselClick(item: any){
                console.log('banner点击：', item)
                // this.$router.push({name: 'ActivityDetail', query:{article_id: item.articleId, channel_name: item.moduleTitle}})
                const url = this.$router.resolve({path: '/articledetail', query:{article_id: item.articleId, channel_name: item.moduleTitle}})
                window.open(url.href, '_blank')
            },
            getCode(){
                console.log("获取验证码")
            },
            onSubmit(){
                this.form.validateFields((err:any, values:any) => {
                    if(!err){
                        console.log('form: ', values);
                        const that = this
                        this.loginLoading = true
                        authApi.login({
                            data: values,
                            success(res:any){
                                localStorage.setItem("token", res.token)
                                that.loginLoading = false
                                location.href = location.href + "#reloaded";
                                location.reload();
                            },
                            fail(error:any){
                                console.log(error.message)
                                message.error(error.message)
                                that.loginLoading = false
                            }
                        })
                    }
                });
            }
        },
        created () {
            const self = this as any
            self.queryArticle()
            self.querySwiperArticle()
        },
    })
